export const useSallyFetch: typeof useFetch = (request, opts?) => {
  const config = useRuntimeConfig()
  const token = useCookie('token')

  return useFetch(request, {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
    baseURL: config.public.baseApiUrl,
    ...opts,
  })
}
